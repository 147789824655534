import { MAP_BOUNDS } from '../constants';

// Map logic for making predictions from user input

/**
 * Predicts the place the user wanted to identify out of places located within Boston, then executes a callback
 * function. Does nothing if no place can be predicted.
 * @param place the invalid place the user entered
 * @param autoService the autocomplete service to use
 * @param placeService the places service to use, assumed to be for the desired map
 * @param callback the function to execute after predicting a place
 */
export function predictPlace(
  place: google.maps.places.PlaceResult,
  autoService: google.maps.places.AutocompleteService,
  placeService: google.maps.places.PlacesService,
  callback: (
    place: google.maps.places.PlaceResult,
    status: google.maps.places.PlacesServiceStatus,
  ) => void,
): void {
  /**
   * A callback function, executes the callback function given to {@link predictPlace} on the first prediction.
   * @param predictions the predictions generated by the autocomplete service
   * @param status the places service completion status
   */
  function callbackOnFirstPrediction(
    predictions: google.maps.places.AutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus,
  ): void {
    if (
      predictions &&
      predictions.length > 0 &&
      status === google.maps.places.PlacesServiceStatus.OK
    ) {
      // Uses the places service to get a place from the first prediction, then executes the callback on it
      placeService.getDetails({ placeId: predictions[0].place_id }, callback);
    }
  }

  // Uses the autocomplete service to make predictions within Boston
  autoService.getPlacePredictions(
    { input: place.name, bounds: MAP_BOUNDS },
    callbackOnFirstPrediction,
  );
}
